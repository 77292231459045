import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '@werfy/ui';

@Component({
  selector: 'wp-radio-option',
  standalone: true,
  templateUrl: 'radio-option.component.html',
  styleUrl: 'radio-option.component.scss',
  imports: [IconComponent, NgIf],
})
export class RadioOptionComponent {
  @Input() public name!: string;
  @Input() public value!: string;

  @Output() public selectOption: EventEmitter<void> = new EventEmitter<void>();

  public isSelected: boolean = false;

  public onSelect(): void {
    this.selectOption.emit();
  }
}
