import { Component, forwardRef, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { InputComponent, InputInterface } from '../input';
import { InputErrorComponent } from '@werfy/ui';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wp-textarea-input',
  standalone: true,
  templateUrl: 'textarea-input.component.html',
  styleUrl: 'textarea-input.component.scss',
  imports: [InputErrorComponent, NgIf, FormsModule],
  providers: [{ provide: InputComponent, useExisting: forwardRef(() => TextareaInputComponent), multi: true }],
})
export class TextareaInputComponent implements ControlValueAccessor, InputInterface {
  @Input() public placeholder: string = '';
  @Input() public numberOfRows: number = 4;
  @Input() public name: string = '';

  public value?: string | null;
  public isDisabled: boolean = false;
  public onChange!: (value: string | null) => void;
  public onTouched!: () => void;

  constructor(@Optional() @Self() public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  public registerOnChange(onChange: (value: string | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string | null): void {
    this.value = value;
  }

  public onValueChange(value: string | null): void {
    this.writeValue(value);
    this.onChange(value);
  }

  public onKeydownEnter(event: Event): void {
    event.stopPropagation();
  }
}
