<span
  (click)="onClick($event)"
  (keydown.enter)="onClick($event)"
  [attr.tabindex]="selectPill.observed ? 0 : undefined"
  [class.pill--clickable]="selectPill.observed"
  [ngClass]="'pill--' + type"
  class="pill"
>
  <span class="pill__icon pill__icon--left">
    <ng-content select="[slot=icon-left]"></ng-content>
  </span>
  <span *ngIf="label" class="pill__label">{{ label }}</span>
  <span class="pill__content">
    <ng-content></ng-content>
  </span>
  <span class="pill__icon pill__icon--right">
    <ng-content select="[slot=icon-right]"></ng-content>
  </span>
</span>
