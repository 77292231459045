<div
  #subNavigationItem
  (keydown.enter)="subNavigationItem.click()"
  [routerLink]="route"
  class="sub-navigation-item"
  routerLinkActive="sub-navigation-item--active"
  tabindex="0"
>
  {{ name }}
</div>
