import { Component, forwardRef, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl } from '@angular/forms';
import { InputComponent, InputInterface } from '../input';
import { NgIf } from '@angular/common';
import { InputErrorComponent } from '../input-error';

@Component({
  selector: 'wp-text-input',
  standalone: true,
  templateUrl: 'text-input.component.html',
  styleUrl: 'text-input.component.scss',
  imports: [FormsModule, NgIf, InputErrorComponent],
  providers: [{ provide: InputComponent, useExisting: forwardRef(() => TextInputComponent), multi: true }],
})
export class TextInputComponent implements ControlValueAccessor, InputInterface {
  @Input() public placeholder: string = '';
  @Input() public name?: string;

  public value?: string | null;
  public isDisabled: boolean = false;
  public onChange!: (value: string | null) => void;
  public onTouched!: () => void;

  constructor(@Optional() @Self() public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  public registerOnChange(onChange: (value: string | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string | null): void {
    this.value = value;
  }

  public onValueChange(value: string): void {
    this.writeValue(value);
    this.onChange(value);
  }
}
