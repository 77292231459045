<div class="tab-group">
  <div class="tab-group__navigation">
    <div
      (click)="onSelectTab(tab)"
      (keydown.enter)="onSelectTab(tab)"
      *ngFor="let tab of tabs"
      [attr.aria-selected]="tab.title === activeTab"
      [class.tab-group__navigation-item--active]="tab.title === activeTab"
      class="tab-group__navigation-item"
      role="tab"
      tabindex="0"
    >
      {{ tab.title }}
    </div>
  </div>
  <div class="tab-group__content">
    <ng-content></ng-content>
  </div>
</div>
