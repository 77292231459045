<span
  [class.fa-brands]="set === 'brands'"
  [class.fa-duotone]="set === 'duotone'"
  [class.fa-flip-horizontal]="flipX"
  [class.fa-flip-vertical]="flipY"
  [class.fa-light]="set === 'light'"
  [class.fa-solid]="set === 'solid'"
  [class.icon--accent]="type === 'accent'"
  [class.icon--reverse]="isReverse"
  [class.icon--spin]="isSpinning"
  [ngClass]="'fa-' + icon"
  class="icon fa-fw"
></span>
