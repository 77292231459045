import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-step',
  standalone: true,
  templateUrl: 'step.component.html',
  styleUrl: 'step.component.scss',
  imports: [NgIf],
})
export class UiStepComponent {
  @Input() public isActive: boolean = false;
  @Input() public isComplete: boolean = false;

  public indicatorIndex: number = 0;
}
