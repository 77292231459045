<div
  (click)="onClick()"
  (keydown.enter)="onClick()"
  [class.summary-card--selectable]="selectCard.observed"
  [ngClass]="'summary-card--' + type"
  class="summary-card"
  tabindex="0"
>
  <div class="summary-card__header">
    <h3 class="summary-card__label">
      <ng-content select="[slot=icon]"></ng-content>
      {{ label }}
    </h3>
    <div class="summary-card__tags">
      <ng-content select="[slot=tags]"></ng-content>
    </div>
  </div>
  <div class="summary-card__body">
    <ng-content></ng-content>
  </div>

  <div class="summary-card__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>
