import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wp-input-description',
  standalone: true,
  templateUrl: './input-description.component.html',
  styleUrl: './input-description.component.scss',
  imports: [NgIf],
})
export class InputDescriptionComponent {
  @Input() public label?: string;
}
