import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-fieldset',
  standalone: true,
  templateUrl: './fieldset.component.html',
  styleUrl: './fieldset.component.scss',
})
export class FieldsetComponent {
  @Input({ required: true }) public label!: string;
}
