<div [class.select--error]="control.touched && control.invalid" [class.select--expanded]="isExpanded" class="select">
  <div (click)="onToggle($event)" (keydown.enter)="onToggle($event)" class="select__select" tabindex="0">
    <div [class.select__label--placeholder]="!label" [innerHTML]="label ?? placeholder" class="select__label"></div>
    <div class="select__icon">
      <wp-icon *ngIf="!isExpanded" icon="angle-down"></wp-icon>
      <wp-icon *ngIf="isExpanded" icon="angle-up"></wp-icon>
    </div>
  </div>
  <div *ngIf="isExpanded" class="select__dropdown">
    <ng-content></ng-content>
  </div>
</div>

<wp-input-error *ngIf="control.touched && control.invalid">
  <ng-content select="[slot=error]"></ng-content>
</wp-input-error>
