import { AfterContentInit, Component, ContentChild, ContentChildren, Input, QueryList } from '@angular/core';
import { InputComponent } from '../input';
import { LabelComponent } from '../label';

@Component({
  selector: 'wp-field',
  standalone: true,
  templateUrl: './field.component.html',
  styleUrl: './field.component.scss',
})
export class FieldComponent implements AfterContentInit {
  @Input({ required: true }) public name!: string;

  @ContentChildren(InputComponent) private inputs!: QueryList<InputComponent>;
  @ContentChild(LabelComponent) private label?: LabelComponent;

  public ngAfterContentInit(): void {
    if (this.label) {
      this.label.for = this.name;
    }
    this.inputs.forEach((input: InputComponent): void => {
      input.name = this.name;
    });
  }
}
