<button
  (click)="onClick()"
  (keydown.enter)="onClick()"
  [class.button--accent]="type === 'accent'"
  [class.button--base]="type === 'base'"
  [class.button--compact]="compact"
  [class.button--dark]="type === 'dark'"
  [class.button--error]="type === 'error'"
  [class.button--full-width]="fullWidth"
  [class.button--has-icon]="!!icon"
  [class.button--info]="type === 'info'"
  [class.button--light]="type === 'light'"
  [class.button--linkedin]="type === 'linkedin'"
  [class.button--neutral]="type === 'neutral'"
  [class.button--no-shadow]="noShadow"
  [class.button--sharp-borders]="isSharp"
  [class.button--special]="type === 'special'"
  [class.button--success]="type === 'success'"
  [class.button--transparent]="type === 'transparent'"
  [class.button--warning]="type === 'warning'"
  [disabled]="disabled"

  [type]="isSubmit ? 'submit' : 'button'"
  class="button"
>
  <span *ngIf="!!icon && alignIcon === 'left'" class="button__icon button__icon--left">
    <wp-icon [flipX]="flipIcon" [icon]="icon" [set]="iconSet"></wp-icon>
  </span>
  <span class="button__content">
    <ng-content></ng-content>
  </span>
  <span *ngIf="!!icon && alignIcon === 'right'" class="button__icon button__icon--right">
    <wp-icon [flipX]="flipIcon" [icon]="icon" [set]="iconSet"></wp-icon>
  </span>
</button>
