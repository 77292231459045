import { Component, EventEmitter, Output } from '@angular/core';
import { IconComponent } from '../../core';

@Component({
  selector: 'wp-bubble',
  standalone: true,
  templateUrl: './bubble.component.html',
  styleUrl: './bubble.component.scss',
  imports: [IconComponent],
})
export class BubbleComponent {
  @Output() public bubbleClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.bubbleClick.emit();
  }
}
