<div
  [class.alert--accent]="type === 'accent'"
  [class.alert--base]="type === 'base'"
  [class.alert--dark]="type === 'dark'"
  [class.alert--error]="type === 'error'"
  [class.alert--flat]="isFlat"
  [class.alert--info]="type === 'info'"
  [class.alert--light]="type === 'light'"
  [class.alert--neutral]="type === 'neutral'"
  [class.alert--special]="type === 'special'"
  [class.alert--success]="type === 'success'"
  [class.alert--transparent]="type === 'transparent'"
  [class.alert--warning]="type === 'warning'"
  class="alert"
>
  <span class="alert__icon">
    <ng-content select="[slot=icon]"></ng-content>
  </span>
  <span class="alert__body">
    <h4 *ngIf="label" class="alert__label">{{ label }}</h4>
    <ng-content></ng-content>
  </span>
</div>
