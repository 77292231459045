import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-divider',
  standalone: true,
  templateUrl: './divider.component.html',
  styleUrl: './divider.component.scss',
})
export class DividerComponent {
  @Input() public align: 'left' | 'center' | 'right' = 'center';
}
