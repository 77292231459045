import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-tag-option',
  standalone: true,
  templateUrl: 'tag-option.component.html',
  styleUrl: 'tag-option.component.scss',
})
export class UiTagOptionComponent {
  @Input() public name!: string;
  @Input({ required: true }) public value!: string;
  @Input() public disabled: boolean = false;

  @Output() public select: EventEmitter<void> = new EventEmitter<void>();
  @Output() public deselect: EventEmitter<void> = new EventEmitter<void>();

  public isSelected: boolean = false;

  public onSelect(): void {
    if (!this.disabled) {
      this.select.emit();
    }
  }

  public onDeselect(): void {
    if (!this.disabled) {
      this.deselect.emit();
    }
  }

  public onToggle(): void {
    if (!this.disabled) {
      this.isSelected ? this.onDeselect() : this.onSelect();
    }
  }
}
