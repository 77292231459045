import { AfterContentInit, Component, ContentChildren, OnDestroy, QueryList } from '@angular/core';
import { MetaNavigationItemComponent } from '../meta-navigation-item';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-meta-navigation',
  standalone: true,
  templateUrl: 'meta-navigation.component.html',
  styleUrl: 'meta-navigation.component.scss',
})
export class MetaNavigationComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(MetaNavigationItemComponent) private items!: QueryList<MetaNavigationItemComponent>;

  private subscriptions$: Subscription = new Subscription();

  public ngAfterContentInit(): void {
    this.items.forEach((item: MetaNavigationItemComponent) => {
      const subscription$ = item.expand.subscribe(() => this.onExpand(item));

      this.subscriptions$.add(subscription$);
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public onExpand(expandedItem?: MetaNavigationItemComponent): void {
    this.items.forEach((item: MetaNavigationItemComponent) => {
      item.isExpanded = item === expandedItem;
    });
  }
}
