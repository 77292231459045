<div [class.breadcrumbs--separator]="!icon" class="breadcrumbs">
  <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
    <span *ngIf="!breadcrumb.route" class="breadcrumbs__breadcrumb">{{ breadcrumb.label }}</span>
    <a
      *ngIf="breadcrumb.route"
      [routerLink]="breadcrumb.route"
      class="breadcrumbs__breadcrumb breadcrumbs__breadcrumb--clickable"
    >
      {{ breadcrumb.label }}
    </a>
    <span *ngIf="!last && !!icon" class="breadcrumbs__icon">
      <wp-icon [icon]="icon" [set]="iconSet"></wp-icon>
    </span>
    <span *ngIf="!last && !icon" class="breadcrumbs__separator">{{ separator }}</span>
  </ng-container>
</div>
