import { NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IconComponent } from '../../core';
import { InputComponent, InputInterface } from '../input';
import { InputErrorComponent } from '../input-error';
import { SelectOptionComponent } from '../select-option';

@Component({
  selector: 'wp-select-input',
  standalone: true,
  templateUrl: './select-input.component.html',
  styleUrl: './select-input.component.scss',
  providers: [{ provide: InputComponent, useExisting: forwardRef(() => SelectInputComponent), multi: true }],
  imports: [NgIf, InputErrorComponent, IconComponent],
})
export class SelectInputComponent implements ControlValueAccessor, InputInterface, AfterContentInit, OnDestroy {
  @ContentChildren(SelectOptionComponent) public options!: QueryList<SelectOptionComponent>;
  @Input() public name?: string;
  @Input() public placeholder?: string;

  public value?: string | null;
  public label?: string;
  public isDisabled: boolean = false;
  public isExpanded: boolean = false;
  public onChange!: (value: string | null) => void;
  public onTouched!: (value: string | null) => void;

  private subscriptions$: Subscription = new Subscription();

  constructor(@Optional() @Self() public control: NgControl) {
    if (control) {
      control.valueAccessor = this;
    }
  }

  @HostListener('document:click')
  public outsideClick(): void {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
  }

  public ngAfterContentInit(): void {
    this.options.forEach((option: SelectOptionComponent): void => {
      if (option.value === this.value) {
        setTimeout(() => {
          option.isSelected = true;
          this.label = option.getLabel();
        }, 0);
      }

      const subscription$ = option.selectOption.subscribe(() => this.onSelect(option));
      this.subscriptions$.add(subscription$);
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  public writeValue(value: string | null): void {
    this.value = value;
    this.checkActiveOption();
  }

  public registerOnChange(onChange: (value: string | null) => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onToggle(event: Event): void {
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }

  private checkActiveOption(): void {
    this.options?.forEach((option: SelectOptionComponent): void => {
      if (option.value === this.value) {
        option.isSelected = true;
        this.label = option.getLabel();
      }
    });
  }

  private onSelect(selectedOption: SelectOptionComponent): void {
    this.options.forEach((option: SelectOptionComponent) => {
      option.isSelected = option === selectedOption;
    });
    this.isExpanded = false;
    this.value = selectedOption.value;
    this.label = selectedOption.getLabel();
    this.onChange(selectedOption.value);
  }
}
