<div
  #select
  (click)="onSelect()"
  (keydown.enter)="onSelect()"
  [class.select-option--selected]="isSelected"
  class="select-option"
  tabindex="0"
>
  <ng-content></ng-content>
</div>
