import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-label',
  standalone: true,
  templateUrl: './label.component.html',
  styleUrl: './label.component.scss',
})
export class LabelComponent {
  @Input() public for?: string;
}
