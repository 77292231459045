export const Style = {
  Base: 'base',
  Accent: 'accent',
  Special: 'special',

  Success: 'success',
  Warning: 'warning',
  Info: 'info',
  Error: 'error',

  Light: 'light',
  Neutral: 'neutral',
  Dark: 'dark',
  Transparent: 'transparent',
} as const;
