import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IconComponent } from '@werfy/ui';

@Component({
  selector: 'wp-modal',
  standalone: true,
  templateUrl: 'modal.component.html',
  styleUrl: 'modal.component.scss',
  imports: [IconComponent],
})
export class ModalComponent implements AfterViewInit {
  @ViewChild('body') public body!: ElementRef;

  @Input() public isWide: boolean = false;
  @Input() public width: 'narrow' | 'medium' | 'wide' = 'medium';
  @Input() public containsOverflow: boolean = false;

  @Output() public outsideClick: EventEmitter<void> = new EventEmitter();
  @Output() public close: EventEmitter<void> = new EventEmitter();

  public isScrollable: boolean = false;

  @HostListener('document:keydown.escape') public escape = (): void => this.close.emit();

  public ngAfterViewInit(): void {
    setTimeout(() => this.onScroll(), 0);
  }

  public onClose(): void {
    this.close.emit();
  }

  public onInsideClick(event: Event): void {
    event.stopPropagation();
    this.onScroll();
  }

  public onScroll(): void {
    this.isScrollable = this.body.nativeElement.scrollHeight > this.body.nativeElement.clientHeight;
  }

  public onOutsideClick(): void {
    this.outsideClick.emit();
  }
}
