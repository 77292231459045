<div
  (click)="onToggle()"
  (keydown.enter)="onToggle()"
  [class.tag-option--selected]="isSelected"
  [class.tag-option--disabled]="disabled"
  class="tag-option"
  [attr.tabindex]="disabled ? -1 : 0"
>
  <ng-content></ng-content>
</div>
