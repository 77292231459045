import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wp-fieldset-group',
  standalone: true,
  templateUrl: './fieldset-group.component.html',
  styleUrl: './fieldset-group.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FieldsetGroupComponent {}
