import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-card-page',
  standalone: true,
  templateUrl: './card-page.component.html',
  styleUrl: './card-page.component.scss',
})
export class CardPageComponent {
  @Input({ required: true }) public logoUrl!: string;
  @Input({ required: true }) public brand!: string;
  @Input({ required: true }) public platform!: string;
}
