<div [class.sidebar--collapsed]="isCollapsed" class="sidebar">
  <div [class.sidebar__brand--collapsed]="isCollapsed" class="sidebar__brand">
    <!--    TODO : Icon when collapsed, otherwise full brand-->
    <img *ngIf="isCollapsed" [src]="brandIcon" alt="{{ brand }} icon" />
    <img *ngIf="!isCollapsed" [src]="brandLogo" alt="{{ brand }} logo" />
  </div>
  <div [class.sidebar__navigation--collapsed]="isCollapsed" class="sidebar__navigation">
    <ng-content select="[slot=navigation]"></ng-content>
  </div>
  <div [class.sidebar__footer--collapsed]="isCollapsed" class="sidebar__footer">
    <button (click)="onToggleCollapsed()" [class.sidebar__collapse--collapsed]="isCollapsed" class="sidebar__collapse">
      <wp-icon *ngIf="!isCollapsed" icon="angles-left"></wp-icon>
      <wp-icon *ngIf="isCollapsed" icon="angles-right"></wp-icon>
    </button>
  </div>
</div>
