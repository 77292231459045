import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wp-meta-navigation-item',
  standalone: true,
  templateUrl: './meta-navigation-item.component.html',
  styleUrl: './meta-navigation-item.component.scss',
  imports: [NgIf],
})
export class MetaNavigationItemComponent {
  @Input() public isImage: boolean = false;

  @Output() public expand: EventEmitter<void> = new EventEmitter<void>();

  public isExpanded: boolean = false;

  @HostListener('document:click')
  public outsideClick(): void {
    if (this.isExpanded) {
      this.isExpanded = false;
    }
  }

  public onInsideClick(event: Event): void {
    event.stopPropagation();
  }

  public onToggle(event: Event): void {
    this.isExpanded = !this.isExpanded;
    event.stopPropagation();

    if (this.isExpanded) {
      this.expand.emit();
    }
  }
}
