<div
  [class.loader--horizontal]="orientation === 'horizontal'"
  [class.loader--vertical]="orientation === 'vertical'"
  class="loader"
>
  <div class="loader__icon">
    <wp-icon [isSpinning]="true" icon="spinner"></wp-icon>
  </div>
  <div class="loader__message">
    <ng-content></ng-content>
  </div>
</div>
