import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'wp-sidebar-page',
  standalone: true,
  templateUrl: 'sidebar-page.component.html',
  styleUrl: 'sidebar-page.component.scss',
})
export class SidebarPageComponent {
  @ViewChild('scroll') public scrollContainer!: ElementRef;

  public scrollToTop(): void {
    this.scrollContainer.nativeElement.scrollTop = 0;
  }
}
