import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'wp-example-message',
  standalone: true,
  templateUrl: './example-message.component.html',
  styleUrl: './example-message.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ExampleMessageComponent {}
