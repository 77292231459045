import { Injectable } from '@angular/core';

const COLLAPSE_KEY: string = 'wp_sidebar_collapse';

@Injectable({ providedIn: 'root' })
export class LayoutStore {
  public getCollapsed(): boolean {
    return localStorage.getItem(COLLAPSE_KEY) !== null;
  }

  public setCollapsed(isCollapsed: boolean): void {
    if (isCollapsed) {
      localStorage.setItem(COLLAPSE_KEY, 'true');
    } else {
      localStorage.removeItem(COLLAPSE_KEY);
    }
  }
}
