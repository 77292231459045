<div
  (click)="onSelect()"
  (keydown.enter)="onSelect()"
  [class.radio-button--selected]="isSelected"
  [ngClass]="'radio-button--' + type"
  class="radio-button"
  tabindex="0"
>
  <div class="radio-button__header">
    <input class="radio-button__input" type="radio" />
    <span class="radio-button__icon">
      <wp-icon *ngIf="!isSelected" icon="circle" set="light"></wp-icon>
      <wp-icon *ngIf="isSelected" icon="circle-dot" set="solid"></wp-icon>
    </span>
    <h4 class="radio-button__label">{{ label }}</h4>
  </div>
  <div class="radio-button__body">
    <ng-content></ng-content>
  </div>
</div>
