<div class="card-page">
  <div class="card-page__header">
    <div class="card-page__brand">
      <img [alt]="brand + ' logo'" [src]="logoUrl">
    </div>
    <h2 class="card-page__platform">{{ platform }}</h2>
  </div>

  <div class="card-page__card">
    <ng-content></ng-content>
  </div>

  <div class="card-page__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>
