<div
  [class.bar--align-center]="align === 'center'"
  [class.bar--align-right]="align === 'right'"
  [class.bar--align-space-between]="align === 'space-between'"
  [class.bar--grow-children]="growChildren"
  [class.bar--no-gap]="noGap"
  class="bar"
>
  <ng-content></ng-content>
</div>
