<div
  (click)="onSelect()"
  (keydown.enter)="onSelect()"
  [class.radio-option--selected]="isSelected"
  class="radio-option"
  tabindex="0"
>
  <div class="radio-option__header">
    <input class="radio-option__input" type="radio" />
    <span class="radio-option__icon">
      <wp-icon *ngIf="!isSelected" icon="circle" set="light"></wp-icon>
      <wp-icon *ngIf="isSelected" icon="circle-dot" set="solid"></wp-icon>
    </span>
    <h4 class="radio-option__label">
      <ng-content></ng-content>
    </h4>
  </div>
</div>
