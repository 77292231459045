<div (click)="onOutsideClick()" (keydown.enter)="onOutsideClick()" class="modal__container" tabindex="-1">
  <div
    (click)="onInsideClick($event)"
    (keydown.enter)="onInsideClick($event)"
    [class.modal--width-l]="width === 'wide'"
    [class.modal--width-s]="width === 'narrow'"
    class="modal"
    tabindex="-1"
  >
    <div [class.modal__header--scrolled]="isScrollable" class="modal__header">
      <h3 class="modal__name">
        <ng-content select="[slot=title]"></ng-content>
      </h3>
      <span (click)="onClose()" (keydown.enter)="onClose()" class="modal__close" tabindex="0">
        <wp-icon icon="close" set="light"></wp-icon>
      </span>
    </div>
    <div #body (scroll)="onScroll()" [class.modal__body--overflow]="containsOverflow" class="modal__body">
      <ng-content></ng-content>
    </div>
    <div [class.modal__footer--scrolled]="isScrollable" class="modal__footer">
      <ng-content select="[slot=footer]"></ng-content>
    </div>
  </div>
</div>
