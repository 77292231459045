<div [attr.title]="isCollapsed ? name : undefined" class="navigation-item">
  <div
    (click)="onSelectItem()"
    (keydown.enter)="onSelectItem()"
    [class.navigation-item__item--active]="isActive"
    [class.navigation-item__item--collapsed]="isCollapsed"
    [class.navigation-item__item--expanded]="isExpanded"
    [class.navigation-item__item--parent]="!!children"
    class="navigation-item__item"
    tabindex="0"
  >
    <span [class.navigation-item__item-icon--collapsed]="isCollapsed" class="navigation-item__item-icon">
      <wp-icon [icon]="icon" set="light"></wp-icon>
    </span>

    <span *ngIf="!isCollapsed" class="navigation-item__item-name">{{ name }}</span>

    <span *ngIf="!!children && !isCollapsed" class="navigation-item__expand-icon">
      <wp-icon *ngIf="!isExpanded" icon="angle-down"></wp-icon>
      <wp-icon *ngIf="isExpanded" icon="angle-up"></wp-icon>
    </span>
  </div>

  <div *ngIf="!!children && isExpanded && !isCollapsed" class="navigation-item__sub-items">
    <wp-sub-navigation-item
      *ngFor="let subItem of children"
      [name]="subItem.name"
      [route]="subItem.route"
    ></wp-sub-navigation-item>
  </div>
</div>
