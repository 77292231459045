import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'wp-menu-item',
  standalone: true,
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
})
export class MenuItemComponent {
  @Output() public itemClick: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.itemClick.emit();
  }
}
