<div class="card">
  <div *ngIf="!!name" class="card__header">
    <h2 class="card__name">{{ name }}</h2>
    <div class="card__actions"><ng-content select="[slot=actions]"></ng-content></div>
  </div>
  <div class="card__body">
    <ng-content></ng-content>
  </div>
  <div class="card__footer">
    <ng-content select="[slot=footer]"></ng-content>
  </div>
</div>
