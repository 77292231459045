import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wp-chat-message',
  standalone: true,
  templateUrl: 'chat-message.component.html',
  styleUrl: 'chat-message.component.scss',
  imports: [NgIf],
})
export class ChatMessageComponent {
  @Input() public type: 'self' | 'reply' = 'self';
}
