<div
  [class.radio-input--advanced]="buttons.length > 0"
  [class.radio-input--error]="control.touched && control.invalid"
  [class.radio-input--vertical]="isVertical"
  class="radio-input"
>
  <ng-content></ng-content>
</div>

<wp-input-error *ngIf="control.touched && control.invalid">
  <ng-content select="[slot=error]"></ng-content>
</wp-input-error>
