import { Component } from '@angular/core';
import { NgIf } from '@angular/common';

@Component({
  selector: 'wp-input-error',
  standalone: true,
  templateUrl: 'input-error.component.html',
  styleUrl: 'input-error.component.scss',
  imports: [NgIf],
})
export class InputErrorComponent {}
