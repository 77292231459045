import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LayoutStore } from './layout.store';

@Injectable({ providedIn: 'root' })
export class LayoutService {
  private _isCollapsed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private layoutStore: LayoutStore) {
    const isCollapsed: boolean = layoutStore.getCollapsed();
    this._isCollapsed$.next(isCollapsed);
  }

  public setCollapsed(isCollapsed: boolean): void {
    this._isCollapsed$.next(isCollapsed);
    this.layoutStore.setCollapsed(isCollapsed);
  }

  public isCollapsed$(): Observable<boolean> {
    return this._isCollapsed$.asObservable();
  }
}
