import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-container',
  standalone: true,
  templateUrl: './container.component.html',
  styleUrl: './container.component.scss',
})
export class ContainerComponent {
  @Input() public width: 'narrow' | 'medium' | 'wide' = 'medium';
}
