<div [class.step--active]="isActive" class="step">
  <div class="step__indicator">
    <div [class.step__indicator-icon--filled]="isActive || isComplete" class="step__indicator-icon">
      <ng-container *ngIf="!isComplete">{{ indicatorIndex }}</ng-container>
      <!--      TODO: Move icon to standardkit/ui first to use it-->
      <!--      <wp-icon *ngIf="isComplete" icon="check-indicator"></wp-icon>-->
    </div>
  </div>
  <div class="step__content">
    <div class="step__body"><ng-content></ng-content></div>
  </div>
</div>
