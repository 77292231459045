import { Component, Input } from '@angular/core';

@Component({
  selector: 'wp-notification-dot',
  standalone: true,
  templateUrl: './notification-dot.component.html',
  styleUrl: './notification-dot.component.scss',
})
export class NotificationDotComponent {
  @Input() public isActive: boolean = false;
}
