import { AfterContentInit, Component, ContentChildren, QueryList } from '@angular/core';
import { UiStepComponent } from '../step';

@Component({
  selector: 'ui-stepper',
  standalone: true,
  templateUrl: 'stepper.component.html',
  styleUrl: 'stepper.component.scss',
})
export class UiStepperComponent implements AfterContentInit {
  @ContentChildren(UiStepComponent) public steps!: QueryList<UiStepComponent>;

  public ngAfterContentInit(): void {
    let index: number = 1;
    this.steps.forEach((item: UiStepComponent) => (item.indicatorIndex = index++));
  }
}
