<input
  (blur)="onTouched()"
  (ngModelChange)="onValueChange($event)"
  [class.text-input--error]="control.touched && control.invalid"
  [disabled]="isDisabled"
  [id]="name"
  [ngModel]="value"
  [placeholder]="placeholder"
  class="text-input"
  type="text"
/>

<wp-input-error *ngIf="control.touched && control.invalid">
  <ng-content></ng-content>
</wp-input-error>
