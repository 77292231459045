<textarea
  (blur)="onTouched()"
  (keydown.enter)="onKeydownEnter($event)"
  (ngModelChange)="onValueChange($event)"
  [class.textarea-input--error]="control.touched && control.invalid"
  [disabled]="isDisabled"
  [id]="name"
  [name]="name"
  [ngModel]="value"
  [placeholder]="placeholder"
  [rows]="numberOfRows"
  class="textarea-input"
></textarea>

<wp-input-error *ngIf="control.touched && control.invalid">
  <ng-content></ng-content>
</wp-input-error>
