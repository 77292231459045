<div class="sidebar-page">
  <div class="sidebar-page__sidebar">
    <ng-content select="[slot=sidebar]"></ng-content>
  </div>
  <main class="sidebar-page__main">
    <div #scroll class="sidebar-page__body">
      <ng-content></ng-content>
    </div>
  </main>
</div>
