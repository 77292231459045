import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Style, StyleType } from '@standardkit/ui';
import { IconComponent } from '@werfy/ui';

@Component({
  selector: 'wp-pill',
  standalone: true,
  templateUrl: 'pill.component.html',
  styleUrl: 'pill.component.scss',
  imports: [NgClass, IconComponent, NgIf],
})
export class PillComponent {
  @Input() public type?: StyleType = Style.Accent;
  @Input() public label?: string;

  @Output() public selectPill: EventEmitter<Event> = new EventEmitter();

  public onClick(event: Event): void {
    this.selectPill.emit(event);
  }
}
