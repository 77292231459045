import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Style, StyleType } from '@standardkit/ui';

@Component({
  selector: 'wp-alert',
  standalone: true,
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
  imports: [NgIf],
})
export class AlertComponent {
  @Input() public type: StyleType = Style.Error;
  @Input() public isFlat: boolean = false;
  @Input() public label?: string;
}
