<div class="meta-navigation-item">
  <div
    (click)="onToggle($event)"
    (keydown.enter)="onToggle($event)"
    [class.meta-navigation-item__toggle--active]="isExpanded"
    [class.meta-navigation-item__toggle--image]="isImage"
    class="meta-navigation-item__toggle"
    tabindex="0"
  >
    <ng-content></ng-content>
  </div>
  <div
    (click)="onInsideClick($event)"
    (keydown.enter)="onInsideClick($event)"
    *ngIf="isExpanded"
    class="meta-navigation-item__expand"
    tabindex="0"
  >
    <ng-content select="[slot=expand]"></ng-content>
  </div>
</div>
