import { NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KeyOf, SortOption, SortOrder, SortOrderType } from '@standardkit/core';
import { ButtonComponent, IconComponent, PillComponent } from '@werfy/ui';
import { take } from 'rxjs';
import { Column, RowAction, TableToggleActionInterface } from '../../../core';

@Component({
  selector: 'ui-table',
  standalone: true,
  templateUrl: 'table.component.html',
  styleUrl: 'table.component.scss',
  imports: [ButtonComponent, NgForOf, NgIf, PillComponent, IconComponent],
})
export class UiTableComponent<T> {
  @Input() public data: T[] = [];
  @Input() public columns: Column<T>[] = [];
  @Input() public sortState?: SortOption<T>[];
  @Input() public dataName?: string;
  @Input() public isStriped: boolean = false;
  @Input() public checkedRows: T[] = [];
  @Input() public canCheckRow: boolean = false;
  @Input() public actions: RowAction<T>[] = [];
  @Input() public toggleAction?: TableToggleActionInterface<T>;
  @Input() public isLoading: boolean = false;

  @Output() public sort: EventEmitter<SortOption<T>[]> = new EventEmitter();
  @Output() public selectRow: EventEmitter<T> = new EventEmitter();
  @Output() public checkedRowsChange: EventEmitter<T[]> = new EventEmitter();

  public allChecked: boolean = false;

  private pendingIndexes: number[] = [];
  private skipToggleClick: boolean = false;

  public onSortColumn(key: KeyOf<T>): void {
    if (!this.isSorted(key)) {
      return this.sort.emit([{ field: key, order: SortOrder.Ascending }]);
    }
    if (this.isSorted(key, SortOrder.Ascending)) {
      return this.sort.emit([{ field: key, order: SortOrder.Descending }]);
    }
    this.sort.emit([]);
  }

  public onRowClick(row: T): void {
    if (!this.selectRow.observed) {
      return;
    }
    if (this.skipToggleClick) {
      this.skipToggleClick = false;
      return;
    }
    this.selectRow.emit(row);
  }

  public onAction(event: Event, action: RowAction<T>, row: T): void {
    event.stopPropagation();
    action.action(row);
  }

  public onCheckAll(): void {
    this.allChecked = !this.allChecked;
    if (this.allChecked) {
      this.checkedRows = this.allChecked ? this.data : [];
    }
    this.checkedRowsChange.emit(this.checkedRows);
  }

  public onCheckRow(event: Event, row: T): void {
    event.stopPropagation();
    if (this.checkedRows.includes(row)) {
      this.checkedRows = this.checkedRows.filter((checkedRow: T): boolean => checkedRow !== row);
      this.allChecked = false;
    } else {
      this.checkedRows.push(row);
      if (this.checkedRows.length === this.data.length) {
        this.allChecked = true;
      }
    }

    this.checkedRowsChange.emit(this.checkedRows);
  }

  public isPending(index: number): boolean {
    return this.pendingIndexes.includes(index);
  }

  public onToggle(row: T, index: number): void {
    this.skipToggleClick = true;
    this.pendingIndexes.push(index); // TODO : Replace with signals
    if (this.toggleAction?.isActive(row)) {
      this.toggleAction
        ?.toggleInactive(row)
        .pipe(take(1))
        .subscribe({
          next: () => this.removePending(index),
          error: () => this.removePending(index),
        });
    } else {
      this.toggleAction
        ?.toggleActive(row)
        .pipe(take(1))
        .subscribe({
          next: () => this.removePending(index),
          error: () => this.removePending(index),
        });
    }
  }

  public isSorted(field: keyof T, order?: SortOrderType): boolean {
    const sortOption = this.sortState?.find((option: SortOption<T>): boolean => option.field === field);
    if (!sortOption) {
      return false;
    }
    if (!order) {
      return true;
    }
    return sortOption.field === field && sortOption.order === order;
  }

  private removePending(indexToRemove: number): void {
    const index: number = this.pendingIndexes.indexOf(indexToRemove);
    if (index === -1) {
      return;
    }
    this.pendingIndexes.splice(index, 1);
  }
}
